// .overview-and-instructions {
//   strong {
//     font-weight: 600;
//   }
// }

.home-page-card {
  border-radius: 2rem;
  padding: 1rem;
}
