.tippy-nav-item {
  position: fixed;
  z-index: 100;
  right: 2rem;
  top: 1rem;
}

.small-screen-navigation {
  list-style-type: none;
  background-color: #dbdbdb;
  width: 10rem;
  margin-top: 2rem;
  padding: 0 1rem;
}
