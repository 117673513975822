@import "/node_modules/antd/dist/antd.variable.min.css";
@import "react-datasheet/lib/react-datasheet.css";
@import "~react-toastify/dist/ReactToastify.css";

// @import "/node_modules/react-grid-layout/css/styles.css";
// @import "/node_modules/react-resizable/css/styles.css";

/***** Your Application Specific Code Goes Here *****/

$primary-color: #d6781e;

#root {
  min-height: 100%;
  position: relative;
  margin: 0;
  background: #f0f2f5;
  font-family: "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
  font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black !important;
}

h1 {
  font-size: 32px;
}

p {
  font-size: 14px;
}

li {
  font-size: 14px;
}

.PnnlCard {
  border-radius: 2rem;
}

.modal {
  .modal-content {
    font-family: "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    font-size: 100%;
  }
}

.ant-modal {
  .ant-modal-content {
    font-family: "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    font-size: 100%;
  }
}

.error-boundary {
  a {
    text-decoration: none;
  }
}

////////////////////////// AntD Styles //////////////////////////

.ant-layout {
  overflow-x: hidden;

  .ant-layout-header {
    width: 100%;
    padding: 0;
    background: none;
  }

  .ant-layout-content {
    padding: 1.5rem 5rem;
    margin: 2rem 0 10rem 0;
    overflow: auto hidden;
    // height: 85rem;
    // background-color: #f7f7f7;

    .ant-card {
      border-radius: 1rem;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border: 1px solid #d9d9d9;
    }
  }

  .ant-layout-footer {
    background-color: $primary-color;
    color: white;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-top: 10rem;

    .footer {
      display: flex;
      justify-content: space-between;
      margin: 0 1rem;
      height: 5rem;

      .footer-left {
        img {
          width: 5rem;
          height: auto;
        }
      }

      .footer-links {
        line-height: 4rem;
        span {
          margin: 0rem 1rem;
          font-size: 15px;
          cursor: pointer;
        }
        a {
          text-decoration: none;
          margin: 0rem 1rem;
          color: white;
          font-size: 15px;
        }
        text-align: center;
        /* unvisited link */
        a:link {
          color: white;
        }

        a:visited {
          color: white;
        }
      }

      .right-links {
        text-align: right;
        line-height: 3rem;

        .dropdown-icon {
          margin: 0;
          svg {
            width: 14px;
            padding-top: 5px;
          }
        }
      }
    }
  }
}

.calculation-data-files-dropdown-menu {
  a {
    text-decoration: none;
    color: black;
    font-size: 15px;
  }
}
